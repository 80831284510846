import NextImage from 'next/image'
import React from 'react'
import styled from 'styled-components'

import { Text } from 'common/UI'
import { Carousel } from 'common/UI/Carousel'
import { TeamCarouselStoryblok, TeamMemberStoryblok } from 'common/types'
import { getImageAttributes } from 'common/utils/content'

import { ClinicSectionHeader } from './ClinicSectionHeader'
import { Editable } from './Editable'

type Props = {
  block: TeamCarouselStoryblok
}

export const TeamCarousel: React.FC<Props> = ({ block, ...props }) => {
  const { header, items_per_view, team } = block

  return (
    <div>
      {header?.[0] && <ClinicSectionHeader block={header?.[0]} />}

      {team.length && (
        <StyledCarousel
          {...props}
          itemsPerView={Number(items_per_view) || 6}
          gap="1.25rem"
          size="small"
        >
          {team.map((teamMember: TeamMemberStoryblok) => {
            const { _uid, image, name, role } = teamMember
            return (
              <Editable block={teamMember} key={_uid}>
                <TeamMember>
                  {image && (
                    <StyledMedia
                      src={getImageAttributes(image).src}
                      width={getImageAttributes(image).width}
                      height={getImageAttributes(image).height}
                      alt={getImageAttributes(image).alt || ''}
                      css={{ pointerEvents: 'none' }}
                    />
                  )}

                  <TeamMemberInfo>
                    {name && (
                      <Text as="p" variant="eighteen/regular">
                        {name}
                      </Text>
                    )}
                    {role && (
                      <TruncatedText as="p" variant="eighteen">
                        {role}
                      </TruncatedText>
                    )}
                  </TeamMemberInfo>
                </TeamMember>
              </Editable>
            )
          })}
        </StyledCarousel>
      )}
    </div>
  )
}

const StyledCarousel = styled(Carousel)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
`

const StyledMedia = styled(NextImage)<{
  src: string
}>`
  width: 100%;
  height: 240px;

  object-fit: cover;
  border-radius: 1rem;
`

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding-bottom: 1.25rem;
`

const TeamMemberInfo = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2px;
`

const TruncatedText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 80%;
`
